/// Portfolio
/// Loop Links
.portfolio-list-home {
	overflow: hidden;

	.portfolio-item {
		width: 100%;
		height: 180px;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		padding: 0 !important;
		margin-right: 20px;
		margin-bottom: 40px;

		&.lg-last{
			margin-right: 0px;
		}

		IMG {
			width: 100%;
			height: auto;
		}

		.gradient {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0.3;
			background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.8), #000 100%);
			cursor: pointer;
		}
	}
}

.portfolio-list {
	.portfolio-item {
		margin-bottom: 60px;
		width: 100%;
		height: 180px;
		position: relative;
		overflow: hidden;
		cursor: pointer;
		padding: 0 !important;
		margin-right: 20px;
		margin-bottom: 40px;

		&.lg-last{
			margin-right: 0px;
		}

		IMG {
			width: 100%;
			height: auto;
		}

		.gradient {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0.3;
			background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.8), #000 100%);
			cursor: pointer;
		}


		H3 {
			position: absolute;
			margin: 0 5%;
			padding: 0 0 4px;
			transition: 0.2s linear;
			text-align: center;
			left: 0px;
			color: #fff;
			width: 90%;
			bottom: -100px;
			font-size: 25px;
		}

		&:hover {
			H3 {
				transform: translateY(-100px);
			}
		}
	}
}

.single-portfolio {
	.row.mt {
		margin-top:0;
	}

	.pt {
		padding-bottom: 30px;
	}

	.details-key,
	.details-value {
		line-height:30px;
		vertical-align:middle;
		margin-bottom: 10px;
	}

	IMG.collaborating-agency-logo {
		filter: grayscale(100%);
	}

	H3 {
		text-align: center;
	}

	HR.portfolio-separator {
		width: 50%;
		margin: 0px auto 50px auto;
	}
}

@media  (min-width: 64.063em) {
	.portfolio-list-home {
		.portfolio-item {
			H3 {
				position: absolute;
				margin: 0 5%;
				padding: 0 0 4px;
				transition: 0.2s linear;
				text-align: center;
				left: 0px;
				color: #fff;
				width: 90%;
				bottom: -100px;
				font-size: 25px;
			}
		}
	}
}
@media  (min-width: 40.063em) {
	.portfolio-list-home {
		.portfolio-item {
			H3 {
				display: block;
			}

			&:hover {
				H3 {
					transform: translateY(-100px);
				}
			}
		}
	}
}
@media only screen and (max-width: 75.000em) {
	.single-portfolio {
		.left-alley,
		.right-alley {
			display:none;
		}
	}

    .portfolio-list-home {
		.portfolio-item {
			H3 {
				display: none;
			}

            .gradient {
                display: none;
            }
		}
	}
}
@media only screen and (max-width: 61.875em) {
	.portfolio-list-home {
		.portfolio-item {
			H3 {
				display: none;
			}
		}
	}

	.portfolio-list {
		/*.gradient {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: #FFF;
			background: linear-gradient(to right, rgba(255, 255, 255, 0.0) 30%, rgba(255, 255, 255, 0.9) 100%);
			cursor: pointer;
		}*/
	}
}
