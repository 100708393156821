/// Footer Widgets
.widget-wrapper {
	padding-bottom: 0px;

	&:last-child {
		padding-bottom: 25px;
	}

	.textwidget {
		P {
		    margin-bottom: 0px;
		}
	}
}
