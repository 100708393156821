pre.line-numbers {
	position: relative;
	padding-left: 3.8em;
	counter-reset: linenumber;

    &>code {
    	position: relative;
    }
}

.line-numbers {
    .line-numbers-rows {
    	position: absolute;
    	pointer-events: none;
    	top: 0;
    	font-size: 100%;
    	left: -3.8em;
    	width: 3em; /* works for line-numbers below 1000 lines */
    	letter-spacing: -1px;
    	border-right: 1px solid #999;
    	-webkit-user-select: none;
    	-moz-user-select: none;
    	-ms-user-select: none;
    	user-select: none;

        &>span {
    		pointer-events: none;
    		display: block;
    		counter-increment: linenumber;

            &:before {
    			content: counter(linenumber);
    			color: #999;
    			display: block;
    			padding-right: 0.8em;
    			text-align: right;
    		}
        }
    }
}

pre[class*="language-"] {
    -webkit-box-shadow: -1px 0px 0px 0px #1abc9c, 0px 0px 0px 1px #dfdfdf !important;
    -moz-box-shadow: -1px 0px 0px 0px #1abc9c, 0px 0px 0px 1px #dfdfdf !important;
    box-shadow: -1px 0px 0px 0px #1abc9c, 0px 0px 0px 1px #dfdfdf !important;
    border-left: 10px solid #1abc9c !important;
}
