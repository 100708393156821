/// Newsletter signups
#mc_embed_signup {			
	FORM {
		padding: 0px !important;

		LABEL[for='mce-EMAIL'] {
			color: #FFFFFF;
		}
	}
}
