.page-template-template-homepage,
.page-template-template-packages {
	&.page-template-template-packages-php {
		.packages-sale {
			margin: 35px 80px 65px;
		}
	}

	.packages-sale {
		H4 {
			font-size: 24px;
			padding-top: 10px;
    		margin-top: 10px;
    		margin-bottom: 15px;
		}

		I.fa {
			&.fa-snowflake-o {
				margin-top: 15px;
				color: #FFF;
				font-size: 10em;
			}
		}

		EM {
			color: #777;
    		font-size: 12px;
		}

		.coupon-code {
			border: 1px dashed #555;
			padding: 2px;
		}
	}
}

