.skillbar {
	position:relative;
	display:block;
	margin-bottom:15px;
	width:100%;
	background:#eee;
	height:35px;
	border-radius:3px;
	transition:0.4s linear;
	transition-property:width, background-color;		

	.skillbar-title {
		position:absolute;
		top:0;
		left:0;
		width:160px;
		font-weight:bold;
		font-size:13px;
		color:#ffffff;
		background:#6adcfa;
		border-top-left-radius:3px;
		border-bottom-left-radius:3px;
		
		SPAN {
			display:block;
			background:rgba(0, 0, 0, 0.1);
			padding:0 20px;
			height:35px;
			line-height:35px;
			border-top-left-radius:3px;
			border-bottom-left-radius:3px;
		}
	}
	
	.skillbar-bar {
		height:35px;
		width:0px;
		background:#6adcfa;
		border-radius:3px;
	}
	
	.skill-bar-percent {
		position:absolute;
		right:10px;
		top:0;
		font-size:11px;
		height:35px;
		line-height:35px;
		color:#ffffff;
		color:rgba(0, 0, 0, 0.4);
	}
	
	.title-color-0 { background: #d35400; }
	.bar-color-0 { background: #e67e22; }
	.title-color-1 { background: #2980b9; }
	.bar-color-1 { background: #3498db; }
	.title-color-2 { background: #2c3e50; }
	.bar-color-2 { background: #2c3e50; }
	.title-color-3 { background: #46465e; }
	.bar-color-3 { background: #5a68a5; }
	.title-color-4 { background: #333333; }
	.bar-color-4 { background: #525252; }
	.title-color-5 { background: #27ae60; }
	.bar-color-5 { background: #2ecc71; }
	.title-color-6 { background: #124e8c; }
	.bar-color-6 { background: #4288d0; }
	.title-color-7 { background: #f7a53b; }
	.bar-color-7 { background: #f7a53b; }
	.title-color-8 { background: #6adcfa; }
	.bar-color-8 { background: #6adcfa; } 
	.title-color-9 { background: #fa6e6e; }
	.bar-color-9 { background: #fa6e6e; }
	.title-color-10 { background: #336699; }
	.bar-color-10 { background: #336699; }
}