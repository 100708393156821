/// Blog Overrides
/// Loop
#white {
	padding-top: 30px;
}

#grey {
	padding-top: 30px;
}

HR.blog-header-separator {
	width: 80%;
	margin: 20px auto;
}

.page-template-blog {
	.post-meta {
    	margin-bottom: 0;
		padding-left: 40px;
		padding-right: 40px;
    	display: inline-block;
    	width: 100%;

		.meta-space {
			font-size: 0.95em;
			color: $medium-gray;

			>SPAN {
				padding-right: 8px;
				margin-right: 4px;

				&:not(:last-child):after {
					position: relative;
					top: 3px;
					left: 8px;
					height: 15px;
					content: no-close-quote;
					width: 1px;
					background: $medium-gray;
					display: inline-block;
					transform: rotate(25deg);
				}
			}

			I {
				margin-right: 4px;
			}

			A {
				color: $medium-gray;
				transition: 0.4s ease-in;
				transition-property: color;

				&:hover,
				&:active {
					color: $blue-green;
				}
			}
		}
	}

	P.blog-excerpt {
		text-align: justify;
		padding: 0 20px;
	}

	P.read-more {
		text-align: center;

		A {
			color: $dark-gray;
			transition: color 200ms ease-in-out;

			.fa.fa-arrow-right {
				opacity: 0;
				transition: opacity 200ms ease-in-out;
				margin-left: 8px;
			}

			&:hover {
				color: $blue-green;

				.fa.fa-arrow-right {
					opacity: 1;
					//transform: translate(15px,0);
				}
			}
		}
	}

	.post-entry {
		margin-top: 10px;
		padding-left: 40px;
		padding-right: 40px;

		P:not(.read-more) {
			text-align: justify;
		}
	}

	.pager {
		margin: 40px 0 60px 0;
	}
}

.single-post {
	#white {
		padding-bottom: 5px;
	}

	.post-meta {
    	margin-bottom: 0;
    	display: inline-block;
    	width: 100%;

		.meta-space {
			font-size: 0.95em;
			color: $medium-gray;

			>SPAN {
				padding-right: 8px;
				margin-right: 4px;

				&:not(:last-child):after {
					position: relative;
					top: 3px;
					left: 8px;
					height: 15px;
					content: no-close-quote;
					width: 1px;
					background: $medium-gray;
					display: inline-block;
					transform: rotate(25deg);
				}
			}

			I {
				margin-right: 4px;
			}

			A {
				color: $medium-gray;
				transition: 0.4s ease-in;
				transition-property: color;

				&:hover,
				&:active {
					color: $blue-green;
				}
			}
		}
	}

	H2 {
		margin-top: 40px;
		text-align: left;
	}

	P.blog-content {
		text-align: justify;
	}

	.wp-caption {
		border-radius: 4px;

		P.wp-caption-text {
			text-align: center;
			font-size: 1.3rem;
			padding: 25px 10px 10px 10px;
			color: $dark-gray;
		}
	}

	.post-entry{
		P {
			text-align: justify;
		}
	}

	PRE[class*="language-"].line-numbers {
		margin-bottom: 30px;
	}

	.footnote_plugin_tooltip_text {
		color: $medium-gray;
	}

	.author-avatar {
		display: none;
	}

	#author-meta{
		display: none;
	}
}

.blog-list-home {
	P {
		color: #333;

		&.blog-entry-subtitle {

		}

		&.blog-entry-date {
			font-size: 14px;
		}
	}
}

.pager {
    .next {
        >A,
        >SPAN {
            float: left;
        }
    }

    .previous {
        >A,
        >SPAN {
            float: right;
        }
    }

    LI {
        A,
        SPAN {
            position: relative;
          	vertical-align: top;
          	padding: 5px 10px;
            font-size: 16px;
            color: #FFF !important;
            text-align: center;
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
            background: #f1c40f;
            border: 0;
            border-bottom: 2px solid #e2b607;
            cursor: pointer;
            box-shadow: inset 0 -2px #e2b607;
        	opacity: 0.85;
        	transition: opacity .25s ease-in-out;
            background: #859596;
    		border-bottom: 2px solid #95a5a6;
    		box-shadow: inset 0 -2px #95a5a6;
            border-radius: 4px;

            &:active {
                background: #859596;
        		border-bottom: 2px solid #95a5a6;
        		box-shadow: inset 0 -2px #95a5a6;
        		top: 1px;
        		outline: none;
        		box-shadow: none;
          		color: #FFF;
        		opacity: 1;
        	}

        	&:hover {
                background: #859596;
        		border-bottom: 2px solid #95a5a6;
        		box-shadow: inset 0 -2px #95a5a6;
        		opacity: 1;
          		color: #FFF;
                text-decoration: none;
        	}
        }
    }
}
