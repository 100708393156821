/*!
 Theme Name:   Dream Encode
 Theme URI:    https://dream-encode.com/
 Description:  Dream-Encode StanleyWP Child Theme
 Author:       David Baumwald
 Author URI:   https://dream-encode.com/
 Template:     stanleywp
 Version:      1.0.0
 License:      GNU General Public License v2 or later
 License URI:  http://www.gnu.org/licenses/gpl-2.0.html
 Tags:         custom
 Text Domain:  stanleywp-child
*/

/// Partials
@import "_partials/mixins";
@import "_partials/variables";
@import '_partials/buttons';

@import '_partials/skills';
@import '_partials/home';
@import '_partials/portfolio';
@import '_partials/portfolio_images';
@import '_partials/blog';
@import '_partials/contact';
@import "_partials/availability";
@import "_partials/debug";
@import "_partials/alerts";
@import '_partials/services';
@import '_partials/packages';
@import '_partials/forms';
@import '_partials/code';
@import '_partials/comments';
@import '_partials/subscribe_form';
@import '_partials/calendar';
@import '_partials/footer_widgets';

@import '_partials/product';
@import '_partials/woocommerce_checkout';
@import '_partials/woocommerce_account';
@import '_partials/cart';

/// Navbar
.navbar-toggle {
	z-index: 999999;
}

.navbar-inverse {
	padding-top: 20px;
}

/// Header Logo
a.logo {
	transition: color 0.1s ease-in, background 0.1s ease-in;
}

.fa-logo-stack-size {
	font-size: 4em !important;
}

.fa-logo-outer-stack-color{
	color:#4D4D4D !important;
}

.fa-logo-inner-stack-color{
	color:#1ABC9C !important;
}

.logo-text {
	color: #FFF;
	font-size: 2.2em;
	font-family: 'Quicksand', sans-serif;
	line-height: 35px;
	margin: 18px 0 0 0px;
	text-transform: lowercase;
	word-wrap: normal;
}

.navbar-inverse .navbar-nav > li > a {
    font-weight: bold;
    border-top: 2px #1ABC9C solid;
}

.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:hover, .navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav > li > a:hover {
    font-weight: bold;
    border-top: 2px #555 solid;
}

.home-content {
	&>p {
		margin-bottom: 25px;
	}

	h3 {
		margin: 2.5em 0 1em 0;
	}

	.wp-block-columns {
		p {
			margin-bottom: 0;
		}
	}
}

/// Footer Copyright
#footer {
	.copyright {
		A {
			color: $dark-gray;
			transition: color 0.1s ease-in-out;

			&:hover,
			&:active {
				color: $blue-green;
			}
		}
	}
}

/// Responsive Logo Stuff
@media (max-width: 768px) {
	.logo-text {
		font-size: 1.6em;
		line-height: 25px;
		margin: 0px 0 0 0px;
	}

	.fa-logo-stack-size {
		font-size: 2em !important;
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.logo-text {
		font-size: 1.9em;
		line-height: 32px;
		margin: 10px 0px 0 0px;
		padding: 0 0 0 30px;
	}

	.fa-logo-stack-size {
		font-size: 3.25em !important;
	}
}
