///Home
/// Pet Projects
.pet-projects-home {
	display: flex;
	align-items: center;
	justify-content: center;

	.project {
		width: 33%;
	}
}

@media only screen and ( max-width: 1200px ) {
	.pet-projects-home {	
		.project {
			width: 50%;
		}
	}
}

@media only screen and ( max-width: 768px ) {
	.pet-projects-home {	
		.project {
			width: 100%;
		}
	}
}
