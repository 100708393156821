.woocommerce-checkout {
	DL.variation {
		padding-left: 20px;

		DD {
			margin-bottom: 0 !important;

			&:last-child {
				margin-bottom: 1em;
			}

			P {
				font-size: 14px;
				font-weight: normal;
				line-height: 1.428571429;
			}
		}
	}

	.shop_table {
        THEAD {
            TH:not(.product-name) {
                text-align: center;
            }

            TH.product-total {
                text-align: right;
                padding-right: 30px;
            }
        }

        TBODY {
    		.order_item {
    			.product-name {
    				font-size: 16px;

    				UL.wc-item-meta {
    					font-size: 14px;
    					margin: 5px 10px;

    					LI {
                            margin: 5px;

    						STRONG{
    							&.wc-item-meta-label {
    								margin-right: 1em;
    								font-size: 14px;
    							}
    						}

    						P {
    							font-size: 14px;
    							line-height: 1.428571429;
    						}
    					}
    				}
    			}

                .product-quantity {
                    text-align: center;
                }

                .product-subtotal {
                    text-align: right;
                    padding-right: 30px;
                }
            }
		}

        TFOOT {
            font-size: 16px;

            TR {
                TH:first-child {
                    text-align: right;
                    padding-right:40px;
                }
                TD:last-child {
                    text-align: right;
                    padding-right: 30px;
                }
            }
        }
	}

	input#submit.alt,
	a.button.alt,
	button.button.alt,
	input.button.alt {
		background-color: #1abc9c;

		&:hover{
			background-color: #16a085;
		}
	}
}
