/// Buttons
.page-template-template-contact {
	.content {
		margin: 20px auto;
	}

	hr {
		width: 65%;
    	margin: 30px auto 30px auto;
	}

	.nf-form-fields-required {
		display: none;
	}

	h4 {
		padding: 10px 0;

		I.fa-calendar {
			margin-right: 15px;
		}
	}
}