/// Buttons
.btn {
	position: relative;
  	vertical-align: top;
  	padding: 5px 10px;
    font-size: 16px;
    color: #FFF !important;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    background: #f1c40f;
    border: 0;
    border-bottom: 2px solid #e2b607;
    cursor: pointer;
    box-shadow: inset 0 -2px #e2b607;
	opacity: 0.85;
	transition: opacity .25s ease-in-out;
	
	&:active {
		top: 1px;
		outline: none;
		box-shadow: none;
  		color: #FFF;
		opacity: 1;
	}
	
	&:hover {
		opacity: 1;
  		color: #FFF;
	}
	
	&.btn-orange {
		background: #f39c12;
		border-bottom: 2px solid #e8930c;
		box-shadow: inset 0 -2px #e8930c;
	}
	
	&.btn-carrot {
		background: #e67e22;
		border-bottom: 2px solid #da751c;
		box-shadow: inset 0 -2px #da751c;
	}
	
	&.btn-pumpkin {
		background: #d35400;
		border-bottom: 2px solid #c54e00;
		box-shadow: inset 0 -2px #c54e00;
	}
	
	&.btn-alizarin {
		background: #e74c3c;
		border-bottom: 2px solid #db4334;
		box-shadow: inset 0 -2px #db4334;
	}
	
	&.btn-pomegranate {
		background: #c0392b;
		border-bottom: 2px solid #b53224;
		box-shadow: inset 0 -2px #b53224;
	}
	
	&.btn-turquoise {
		background: #1abc9c;
		border-bottom: 2px solid #12ab8d;
		box-shadow: inset 0 -2px #12ab8d;
	}
	
	&.btn-green-sea {
		background: #16a085;
		border-bottom: 2px solid #14947b;
		box-shadow: inset 0 -2px #14947b;
	}
	
	&.btn-emerald {
		background: #2ecc71;
		border-bottom: 2px solid #28be68;
		box-shadow: inset 0 -2px #28be68;
	}
	
	&.btn-nephritis {
		background: #27ae60;
		border-bottom: 2px solid #219d55;
		box-shadow: inset 0 -2px #219d55;
	}
	
	&.btn-peter-river {
		background: #3498db;
		border-bottom: 2px solid #2a8bcc;
		box-shadow: inset 0 -2px #2a8bcc;
	}
	
	&.btn-belize-hole {
		background: #2980b9;
		border-bottom: 2px solid #2475ab;
		box-shadow: inset 0 -2px #2475ab;
	}
	
	&.btn-amethyst {
		background: #9b59b6;
		border-bottom: 2px solid #8d4ca7;
		box-shadow: inset 0 -2px #8d4ca7;
	}
	
	&.btn-wisteria {
		background: #8e44ad;
		border-bottom: 2px solid #80399d;
		box-shadow: inset 0 -2px #80399d;
	}
	
	&.btn-wet-asphalt {
		background: #34495e;
		border-bottom: 2px solid #263849;
		box-shadow: inset 0 -2px #263849;
	}
	
	&.btn-wet-asphalt {
		background: #34495e;
		border-bottom: 2px solid #263849;
		box-shadow: inset 0 -2px #263849;
	}
	
	&.btn-midnight-blue {
		background: #2c3e50;
		border-bottom: 2px solid #22303f;
		box-shadow: inset 0 -2px #22303f;
	}
	
	&.btn-clouds {
		background: #ecf0f1;
		border-bottom: 2px solid #dadedf;
		box-shadow: inset 0 -2px #dadedf;
	}
	
	&.btn-silver {
		background: #bdc3c7;
		border-bottom: 2px solid #acb2b7;
		box-shadow: inset 0 -2px #acb2b7;
	}
	
	&.btn-concrete {
		background: #859596;
		border-bottom: 2px solid #95a5a6;
		box-shadow: inset 0 -2px #95a5a6;
	}
	
	&.btn-asbestos {
		background: #7f8c8d;
		border-bottom: 2px solid #6d7b7c;
		box-shadow: inset 0 -2px #6d7b7c;
	}
	
	&.btn-asbestos {
		background: #7f8c8d;
		border-bottom: 2px solid #6d7b7c;
		box-shadow: inset 0 -2px #6d7b7c;
	}
	
	&.btn-graphite {
		background: #454545;
		border-bottom: 2px solid #2f2e2e;
		box-shadow: inset 0 -2px #2f2e2e;
	}
}