.availability-type {
	font-weight: bold;
	text-align: left;	
}

.availability-value {
	font-weight: normal;
	text-align: center;
}

.availability-note {
	P {
		font-weight: normal;
		text-align: left;
		font-size: 12px;
		padding: 15px 40px 15px 5px;
	}
}

