.woocommerce-cart {
	.woocommerce-message {
		border-top-color: #16a085;
	}

	.variation {
		P {
			font-size: inherit;
			line-height: inherit;
			margin-bottom: inherit;
		}
	}

	.coupon {
		INPUT[type='text'] {
			width: 115px !important;
		}

		INPUT[name='apply_coupon'] {

		}
	}

	.checkout-button {
		&.alt{
			background-color: #859596 !important;
			opacity: 0.85 !important;
			transition: opacity .25s ease-in-out !important;

			&:hover {
				opacity: 1 !important;
			}
		}
	}
}

