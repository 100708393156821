.page-template-template-calendar {
    .post-entry {
        min-height: 500px;

        .ui.tbk-grid .tbk-row.tb-days > .tbk-column {
            min-height: 75px;
        }

        .tbk-schedule-slot {
            width: 100%;
        }
    }
}
