.woocommerce-account {
	.woocommerce-MyAccount-navigation {
		width: 20%;
		
		ul {
			li {
				text-align: left;

				&.is-active {
					a {
						font-weight: bold;
						color: #7b7b7b;
					    outline: 0;
					    text-decoration: none;
					}
				}
			}
		}
	}

	.woocommerce-MyAccount-content {
		float: right;
		width: 75%;

		.woocommerce-orders-table {
			th:last-child {
				text-align: center;
			}

			td {
				&:last-child {
					text-align: center;
				}

				&.woocommerce-orders-table__cell-order-total {
					text-align: right;
				}
			}
		}
	}

	.woocommerce-table__product-table.product-qty,
	.woocommerce-table__product-qty.product-qty {
		text-align: right;
	}

	td.product-name {
		font-size: 16px;

		.wc-item-meta {
			.wc-item-meta-label {
				margin-right: 15px;
			}

			li {
				padding: 5px 10px;

				p {
					font-size: 14px;
					line-height: 21px;
				}
			} 
		} 
	}
}

@media (min-width: 1200px) {
	.woocommerce-account {
		.container {
			width: 1440px;
		}
	}
}