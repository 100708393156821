/// Forms
.page-template-template-packages {
	[id='nf-form-2-cont'] {	
		color: #424242;

		LABEL {
			font-size: 1.3em;
		}

		.nf-field-description {
			P {
				text-align: left;
				font-size: 14px;
			}
		}
	}
}