/// Buttons
.comments-area {
    .comment-meta {
        .fn {
            font-size: 16px;
        }

        .comment-date {
            font-size: 14px;
        }

        A {
            color: #555;
        }
    }

	.bypostauthor {
        .comment-body {
            background-color: rgba(26, 188, 156, .7);
            color: #FFF;

            CODE {
                color: #1abc9c;
            }

            A {
                color: #FFF;
                font-weight: bold;
            }
        }
    }
}
