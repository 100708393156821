/// Services
.page-template-template-services {
	.service-icon {
		font-size: 15rem;
		margin-top: -10px;
		
		&:hover {
			color: #333;
		}
		
		&.wordpress {
			&:before {
				font-family: FontAwesome!important;
				content: "\f19a";
				font-size: 14rem;
			}
		}
		
		&.woocommerce {
			&:before {
				font-family: WooCommerce!important;
				content: "\e03d";
			}
		}
		
		&.back-end {
			&:before {
				font-family: FontAwesome!important;
				content: "\f233";
			}
		}
		
		&.consulting {
			&:before {
				font-family: FontAwesome!important;
				//content: "\f2b5";
				content: "\f27e";
			}
		}
		
		&.craft {
			&:before {
				content: "";
				display: block;
  				height: 165px;
				background-image: url("/content/themes/stanleywp-child/assets/dist/images/craft-logo-gray.jpg");
				margin-top: 40px;
			}
		}
	}

	.col-lg-2 {
		&:not(.col-lg-push-10)	{
			.service-icon {
				margin-top: -25px;
			}
		}
	}
	
	.ww,
	.gw {
		padding-bottom: 30px;
    padding-top: 30px;
	}
	
	.ww {
		background-color: #fff;
	}
	
	.gw {
		background-color: #f2f2f2;
	}
}

/// Service Group
.page-template-template-service-group {
	.white {
		background-color: #FFF;
		padding-bottom: 30px;
    	padding-top: 30px;
	}
	
	.gray {
		background-color: #F2F2F2;
		padding-bottom: 30px;
    	padding-top: 30px;
	}
	
	.service-icon {
		margin-top: 25px;
		font-size: 15rem;
		transition: color 0.25s ease-in-out;
		
		&:hover {
			color: #1abc9c;
		}
	}
}

/// Default Page
.page-template-default {
	.white {
		background-color: #FFF;
		padding-bottom: 30px;
    	padding-top: 30px;
	}
	
	.gray {
		background-color: #F2F2F2;
		padding-bottom: 30px;
    	padding-top: 30px;
	}
		
	H2 {
		&.mt {
			margin: 0;
			padding: 0;
			margin-top: 30px;
			margin-bottom: 40px;
		}
	}
	
	.service-types {	
		>DIV[class*='col-'] {
			min-height: 250px;
		}

		>DIV[class*='col-']:last-child {
			min-height: 110px;
		}

		.service-type-icon {
			margin-top: 15px;
			font-size: 4rem;
		}
		
		P {
			font-size: 14px;
		}
		
		.btn {
			margin-bottom: 60px;
		}
	}

	&.page-id-1030 {
		P.contact {
			margin-top: 30px;
		}
	}
}

/// Loop Links 
.service-list-home {
	overflow: hidden;

	.service-item { 
		width: 100%;
		height: 180px;
		position: relative;
		overflow: hidden;
		cursor: pointer; 
		padding: 0 !important;
		margin-right: 20px;
		margin-bottom: 40px; 
		
		&.lg-last{
			margin-right: 0px;
		}
		
		IMG {
			width: 100%;
			height: auto;
		}

		.gradient {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0.2;
			background: linear-gradient(to bottom, transparent 25%, rgba(0, 0, 0, 0.1), #000 100%);
			cursor: pointer; 
		}
	}
}

.service-list {	
	.portfolio-item {			
		margin-bottom: 60px;
	}
}

.single-service {
	.row.mt { 
		margin-top:0; 
	}
	
	.pt {
		padding-bottom: 30px;
	}
	
	.details-key, 
	.details-value { 
		line-height:30px; 
		vertical-align:middle; 
		margin-bottom: 10px;
	}
	
	IMG.collaborating-agency-logo {
		filter: grayscale(100%); 
	}
	
	H3 {
		text-align: center;
	}
	
	HR.portfolio-separator {
		width: 50%;
		margin: 0px auto 50px auto;
	}
}

@media  (min-width: 64.063em) {
	.service-list-home {
		.service-item {			
			H3 {
				position: absolute;
				margin: 0 5%;
				padding: 0 0 4px;
				transition: 0.2s linear;
				text-align: center;
				left: 0px;
				color: #fff;
				width: 90%;
				bottom: -100px;
				font-size: 25px; 
			}
		}
	}
}
@media  (min-width: 40.063em) {
	.service-list-home {
		.service-item {
			H3 {
				display: block;
			}
			
			&:hover {
				H3 {
					transform: translateY(-120px);
				}
			}
		}
	} 
}
@media only screen and (max-width: 75.000em) {
	.service-portfolio {
		.left-alley,
		.right-alley {
			display:none;
		}
	}
}
@media only screen and (max-width: 61.875em) {
	.service-list-home {
		.service-item {			
			H3 {
				display: none; 				
			}
		}
	}
	
	.service-list {
		/*.gradient {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: #FFF;
			background: linear-gradient(to right, rgba(255, 255, 255, 0.0) 30%, rgba(255, 255, 255, 0.9) 100%);
			cursor: pointer; 
		}*/
	}
}