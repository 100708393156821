/// Packages
/// Loop Links 
.page-template-template-packages {
	H1 {
		font-size: 3.2em;
	}

	.packages {	
		margin-top: 50px;

		.panel {			
			H1,
			H2 {
				color: #333;
				font-size: 30px;
				font-weight: 300;
				margin: 15px;
			}
			
			&.panel-primary,
			&.panel-danger {
				H2 {
					color: #FFF;
				}
			}

			&.panel-primary {
				margin-top: -50px;
			}

			.panel-body {
				padding: 0px;
				text-align: center;
			}
			
			.package-price {
				background-color: rgba(220,220,220,.17);
				box-shadow: 0 1px 0 #dcdcdc, inset 0 1px 0 #fff;
				padding: 20px;
				margin: 0;
				
				H1 {
					line-height: 1em;
					padding: 0;
					margin: 0;
				}
			}
			
			.subscript {
				font-size: 25px;
			}
			
			.cnrflash {
				position: absolute;
				top: -9px;
				right: 4px;
				z-index: 1; /*Set overflow to hidden, to mask inner square*/
				overflow: hidden; /*Set size and add subtle rounding  		to soften edges*/
				width: 100px;
				height: 100px;
				border-radius: 3px 5px 3px 0;
				
				.cnrflash-inner {
					/*Set position, make larger then 			container and rotate 45 degrees*/
					position: absolute;
					bottom: 0;
					right: 0;
					width: 145px;
					height: 145px;
					-ms-transform: rotate(45deg); /* IE 9 */
					-o-transform: rotate(45deg); /* Opera */
					-moz-transform: rotate(45deg); /* Firefox */
					-webkit-transform: rotate(45deg); /* Safari and Chrome */
					-webkit-transform-origin: 100% 100%; /*Purely decorative effects to add texture and stuff*/ /* Safari and Chrome */
					-ms-transform-origin: 100% 100%;  /* IE 9 */
					-o-transform-origin: 100% 100%; /* Opera */
					-moz-transform-origin: 100% 100%; /* Firefox */
					background-image: linear-gradient(90deg, transparent 50%, rgba(255,255,255,.1) 50%), linear-gradient(0deg, transparent 0%, rgba(1,1,1,.2) 50%);
					background-size: 4px,auto, auto,auto;
					background-color: #aa0101;
					box-shadow: 0 3px 3px 0 rgba(1,1,1,.5), 0 1px 0 0 rgba(1,1,1,.5), inset 0 -1px 8px 0 rgba(255,255,255,.3), inset 0 -1px 0 0 rgba(255,255,255,.2);
					
					&:before, 
					&:after {
						content: " ";
						display: block;
						position: absolute;
						bottom: -16px;
						width: 0;
						height: 0;
						border: 8px solid #800000;
					}
					
					&:before {
						left: 1px;
						border-bottom-color: transparent;
						border-right-color: transparent;
					}
					&:after {
						right: 0;
						border-bottom-color: transparent;
						border-left-color: transparent;
					}
					
					.cnrflash-label {
						/*Make the label look nice*/
						position: absolute;
						bottom: 0;
						left: 0;
						display: block;
						width: 100%;
						padding-bottom: 5px;
						color: #fff;
						text-shadow: 0 1px 1px rgba(1,1,1,.8);
						font-size: 0.95em;
						font-weight: bold;
						text-align: center;
					}
				}
			}
			
			.table {
				margin-bottom: 5px;

				TBODY {
					TR.active {
						//font-size: 20px;
						//line-height: 20px;
						font-weight: bold;
					}
				}
			}
		}
	}


	.white {
		background-color: #FFF;
		padding-bottom: 30px;
    	padding-top: 30px;
	}
	
	.gray {
		background-color: #F2F2F2;
		padding-bottom: 30px;
    	padding-top: 30px;
	}

	.benefit {
		margin-bottom: 50px;

		H4 {
			transition: 0.3s ease-in-out color;
			margin-top: 20px;
		
			&:hover {
				color: #1abc9c;
			}
			I {
				font-size: 4em;
				margin-bottom: 25px;
			}
		}

		P {
			padding: 0px 30px;
		}
	}

	.benefit-text {
		P {
			padding: 15px 25%;
		}		
	}

	.benefit-divider {
		border-left: 1px solid #999;
		height: 80%;
		margin: auto;
	}

	H3,
	H4 {
		margin-bottom: 15px;
		margin-top: 50px;
	}

	.packages-details {
		margin-top: 50px;
	}

	P {
		margin-bottom: 25px;

		&.no-mb {
			margin-bottom: 5px;
			margin-top: -10px;
		}

		&.note {
			margin-bottom: 0px;
			margin-top: 0px;
		}
	}

	UL.package-benefits {
		text-align: left;
		list-style-type: disc;
		margin-bottom: 35px;
		margin-left: 25px;
	}

	H2 {
		font-size: 3em;
    	margin-bottom: 30px;
	}
}