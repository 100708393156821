$assets: '/assets';

// Typography
$open-sans-serif: 'Open Sans', sans-serif;
$base-font-family: $open-sans-serif;

// Colors
$beige-bg: #F0E7DA;
$dark-blue: #178BB2;
$light-blue: #e9eff2;
$blue-lines: #BFCBD1;
$green: #89B451;
$red: #D52430;
$yellow: #eec005;
$dark-gray: #2E4052;
$medium-gray: #636466;
$light-gray: #FAF9F9;
$gray-borders: #D6D8D9;

$black: #555;
$black-text: #555;
$dark-gray: #7B7B7B;
//$dark-gray: #2E4052;
//$medium-gray: #636466;
$medium-gray: #b3b3b3;
$light-gray: #F2F2F2;
//$light-gray: #FAF9F9;
$gray-borders: #D6D8D9;

$blue-green: #1abc9c;
