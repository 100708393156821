/*
	Containers
*/
#content-wrapper, #example-wrapper {
	height: 100%;
}

.scrollContainer {
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%
}
.scrollContainer {
	height: 100%;
	margin: auto;
}

#example-wrapper { 
	.scrollContent {
		height: 100%;
	}
	
	.scrollContainer .scrollContent {
		width: 100%;
		height: auto;
	}
	
	.scrollContent {
		font-size: 0;
		
		div {
			font-size: 13px;
		}
	}
}

.fullheight {
	min-height: 100%;
}
.doublewidth {
	min-width: 200%;
}

	
.portfolio-image {

	.spacer {
		text-align: center;
		min-height: 100px;
	}
	.spacer.s0 {
		min-height: 1px;
	}
	.spacer.s1 {
		min-height: 100px;
	}
	.spacer.s2 {
		min-height: 200px;
	}
	.spacer.s3 {
		min-height: 300px;
	}
	.spacer.s4 {
		min-height: 400px;
	}
	.spacer.s5 {
		min-height: 500px;
	}
	.spacer.s6 {
		min-height: 600px;
	}
	.spacer.s7 {
		min-height: 700px;
	}
	.spacer.s8 {
		min-height: 800px;
	}
	.spacer.s9 {
		min-height: 900px;
	}
	.spacer.s10 {
		min-height: 1000px;
	}
	.spacer.s_viewport {
		min-height: 100%;
	}
	.spacer.s_viewport {
		min-width: 100%;
	}
	
	.hint {
		display: inline-block;
		position: relative;
		border: 0px solid white;
		text-align: center;
		vertical-align: middle;
		padding: 0 5px;
		min-width: 100px;
		width:100%;
		margin: 0 auto 0 auto;
		background-color: $blue-green;
		color: #FFF;
		opacity: 1;
		transition: opacity .25s ease-in-out;
		z-index: 99;
		
		&.active {
			opacity: 1;
		}
		
		H5 {
			color: #FFF;
			text-align:center;
			font-size: 16px;
		}
		
		P {
			margin: 5px 15px 5px 15px;
			font-size: 14px;
		}
		
		A {
			color: white;
		}
		
		&.right {
			margin-left: -10px;
			border-radius: 0px 8px;
			box-shadow: 3px 3px 8px #CCC;
		
			&:after{
				position: absolute;
				top: 0%;
				left: 0%;
				width: 0;
				height: 0;
				margin-left: -40px;
				margin-top: 0px;
				content: ' ';
				border-top: 0px solid transparent;
				border-right: 20px solid #1abc9c;
				border-bottom: 20px solid transparent;
				border-left: 20px solid transparent;
			}
		
			H5 {
				text-align: left;
				margin-left:10px;
				
				I {
					margin-left:8px;
				}
			}
			
			P {
				text-align: left;
			}
		}
		
		&.left {
			margin-left: 10px;
			border-radius: 8px 0px;
			box-shadow: -3px 3px 8px #CCC;
		
			&:after{
				position: absolute;
				top: 0%;
				right: 0%;
				width: 0;
				height: 0;
				margin-right: -40px;
				margin-top: 0px;
				content: ' ';
				border-top: 0px solid transparent;
				border-left: 20px solid #1abc9c;
				border-bottom: 20px solid transparent;
				border-right: 20px solid transparent;
			}
		
			H5 {
				text-align: right;
				margin-right:15px;
				
				I {
					margin-right:8px;
				}
			}
			
			P {
				text-align: right;
			}
		}
		
	}
}