/// Blog Overrides
/// Loop
.woocommerce, 
.woocommerce-page {
	div.product {
		div.images {
			img {
				width: 92%;
			}
		}

		.summary {
			float: none;
    		width: 98%;

			.price {
				color: #666;
				font-size: 2.5em;
			}

			.woocommerce-product-details__short-description {
				p { 
					padding: 15px;
				}
			}
		}

		.woocommerce-tabs {
			ul.tabs { 
				margin: 0 0 0; 
				padding: 0;
				text-align: left;

				li.description_tab {
					display: none;
				}
			} 

			.panel {
		    	padding: 15px;

		    	P {
		    		text-align: left;
		    	}

		    	H2 {
		    		text-align: left;
		    	}
			}

			.woo-product-subscription-package-extra-text {
				P {
					margin: 30px auto;
					text-align: center;
				}
			}
		}

		form.cart {
			.quantity {
				float: none !important;
			}

			.single_add_to_cart_button {
				float: none;
				margin-top: 5px;
			}
			.price {
				color: $blue-green;
			}
			.variations {
				float: left;
				width: 50%;
			}

			a.reset_variations {
    			display: none !important;
			}

			.single_variation_wrap {
				float: left;
				width: 50%;

				.woocommerce-variation {
					&.single_variation {
						float: left;
						width: 60%;

						.woocommerce-variation-price {
							float: left;
							width: 100%;
							text-align: left;
						}
					}
				}

				.woocommerce-variation-add-to-cart {
					float: left;
					width: 40%;
				}
			}
		}

		div.summary {
			float: none;
    		width: 98%;
		}
	}
}