/*!
 Theme Name:   Dream Encode
 Theme URI:    https://dream-encode.com/
 Description:  Dream-Encode StanleyWP Child Theme
 Author:       David Baumwald
 Author URI:   https://dream-encode.com/
 Template:     stanleywp
 Version:      1.0.0
 License:      GNU General Public License v2 or later
 License URI:  http://www.gnu.org/licenses/gpl-2.0.html
 Tags:         custom
 Text Domain:  stanleywp-child
*/

.btn {
  position: relative;
  vertical-align: top;
  padding: 5px 10px;
  font-size: 16px;
  color: #FFF !important;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  background: #f1c40f;
  border: 0;
  border-bottom: 2px solid #e2b607;
  cursor: pointer;
  box-shadow: inset 0 -2px #e2b607;
  opacity: 0.85;
  transition: opacity .25s ease-in-out;
}

.btn:active {
  top: 1px;
  outline: none;
  box-shadow: none;
  color: #FFF;
  opacity: 1;
}

.btn:hover {
  opacity: 1;
  color: #FFF;
}

.btn.btn-orange {
  background: #f39c12;
  border-bottom: 2px solid #e8930c;
  box-shadow: inset 0 -2px #e8930c;
}

.btn.btn-carrot {
  background: #e67e22;
  border-bottom: 2px solid #da751c;
  box-shadow: inset 0 -2px #da751c;
}

.btn.btn-pumpkin {
  background: #d35400;
  border-bottom: 2px solid #c54e00;
  box-shadow: inset 0 -2px #c54e00;
}

.btn.btn-alizarin {
  background: #e74c3c;
  border-bottom: 2px solid #db4334;
  box-shadow: inset 0 -2px #db4334;
}

.btn.btn-pomegranate {
  background: #c0392b;
  border-bottom: 2px solid #b53224;
  box-shadow: inset 0 -2px #b53224;
}

.btn.btn-turquoise {
  background: #1abc9c;
  border-bottom: 2px solid #12ab8d;
  box-shadow: inset 0 -2px #12ab8d;
}

.btn.btn-green-sea {
  background: #16a085;
  border-bottom: 2px solid #14947b;
  box-shadow: inset 0 -2px #14947b;
}

.btn.btn-emerald {
  background: #2ecc71;
  border-bottom: 2px solid #28be68;
  box-shadow: inset 0 -2px #28be68;
}

.btn.btn-nephritis {
  background: #27ae60;
  border-bottom: 2px solid #219d55;
  box-shadow: inset 0 -2px #219d55;
}

.btn.btn-peter-river {
  background: #3498db;
  border-bottom: 2px solid #2a8bcc;
  box-shadow: inset 0 -2px #2a8bcc;
}

.btn.btn-belize-hole {
  background: #2980b9;
  border-bottom: 2px solid #2475ab;
  box-shadow: inset 0 -2px #2475ab;
}

.btn.btn-amethyst {
  background: #9b59b6;
  border-bottom: 2px solid #8d4ca7;
  box-shadow: inset 0 -2px #8d4ca7;
}

.btn.btn-wisteria {
  background: #8e44ad;
  border-bottom: 2px solid #80399d;
  box-shadow: inset 0 -2px #80399d;
}

.btn.btn-wet-asphalt {
  background: #34495e;
  border-bottom: 2px solid #263849;
  box-shadow: inset 0 -2px #263849;
}

.btn.btn-wet-asphalt {
  background: #34495e;
  border-bottom: 2px solid #263849;
  box-shadow: inset 0 -2px #263849;
}

.btn.btn-midnight-blue {
  background: #2c3e50;
  border-bottom: 2px solid #22303f;
  box-shadow: inset 0 -2px #22303f;
}

.btn.btn-clouds {
  background: #ecf0f1;
  border-bottom: 2px solid #dadedf;
  box-shadow: inset 0 -2px #dadedf;
}

.btn.btn-silver {
  background: #bdc3c7;
  border-bottom: 2px solid #acb2b7;
  box-shadow: inset 0 -2px #acb2b7;
}

.btn.btn-concrete {
  background: #859596;
  border-bottom: 2px solid #95a5a6;
  box-shadow: inset 0 -2px #95a5a6;
}

.btn.btn-asbestos {
  background: #7f8c8d;
  border-bottom: 2px solid #6d7b7c;
  box-shadow: inset 0 -2px #6d7b7c;
}

.btn.btn-asbestos {
  background: #7f8c8d;
  border-bottom: 2px solid #6d7b7c;
  box-shadow: inset 0 -2px #6d7b7c;
}

.btn.btn-graphite {
  background: #454545;
  border-bottom: 2px solid #2f2e2e;
  box-shadow: inset 0 -2px #2f2e2e;
}

.skillbar {
  position: relative;
  display: block;
  margin-bottom: 15px;
  width: 100%;
  background: #eee;
  height: 35px;
  border-radius: 3px;
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.skillbar .skillbar-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  background: #6adcfa;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.skillbar .skillbar-title SPAN {
  display: block;
  background: rgba(0, 0, 0, 0.1);
  padding: 0 20px;
  height: 35px;
  line-height: 35px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.skillbar .skillbar-bar {
  height: 35px;
  width: 0px;
  background: #6adcfa;
  border-radius: 3px;
}

.skillbar .skill-bar-percent {
  position: absolute;
  right: 10px;
  top: 0;
  font-size: 11px;
  height: 35px;
  line-height: 35px;
  color: #ffffff;
  color: rgba(0, 0, 0, 0.4);
}

.skillbar .title-color-0 {
  background: #d35400;
}

.skillbar .bar-color-0 {
  background: #e67e22;
}

.skillbar .title-color-1 {
  background: #2980b9;
}

.skillbar .bar-color-1 {
  background: #3498db;
}

.skillbar .title-color-2 {
  background: #2c3e50;
}

.skillbar .bar-color-2 {
  background: #2c3e50;
}

.skillbar .title-color-3 {
  background: #46465e;
}

.skillbar .bar-color-3 {
  background: #5a68a5;
}

.skillbar .title-color-4 {
  background: #333333;
}

.skillbar .bar-color-4 {
  background: #525252;
}

.skillbar .title-color-5 {
  background: #27ae60;
}

.skillbar .bar-color-5 {
  background: #2ecc71;
}

.skillbar .title-color-6 {
  background: #124e8c;
}

.skillbar .bar-color-6 {
  background: #4288d0;
}

.skillbar .title-color-7 {
  background: #f7a53b;
}

.skillbar .bar-color-7 {
  background: #f7a53b;
}

.skillbar .title-color-8 {
  background: #6adcfa;
}

.skillbar .bar-color-8 {
  background: #6adcfa;
}

.skillbar .title-color-9 {
  background: #fa6e6e;
}

.skillbar .bar-color-9 {
  background: #fa6e6e;
}

.skillbar .title-color-10 {
  background: #336699;
}

.skillbar .bar-color-10 {
  background: #336699;
}

.pet-projects-home {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pet-projects-home .project {
  width: 33%;
}

.portfolio-list-home {
  overflow: hidden;
}

.portfolio-list-home .portfolio-item {
  width: 100%;
  height: 180px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  margin-right: 20px;
  margin-bottom: 40px;
}

.portfolio-list-home .portfolio-item.lg-last {
  margin-right: 0px;
}

.portfolio-list-home .portfolio-item IMG {
  width: 100%;
  height: auto;
}

.portfolio-list-home .portfolio-item .gradient {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.8), #000 100%);
  cursor: pointer;
}

.portfolio-list .portfolio-item {
  margin-bottom: 60px;
  width: 100%;
  height: 180px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  margin-right: 20px;
  margin-bottom: 40px;
}

.portfolio-list .portfolio-item.lg-last {
  margin-right: 0px;
}

.portfolio-list .portfolio-item IMG {
  width: 100%;
  height: auto;
}

.portfolio-list .portfolio-item .gradient {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.3;
  background: linear-gradient(to bottom, transparent 50%, rgba(0, 0, 0, 0.8), #000 100%);
  cursor: pointer;
}

.portfolio-list .portfolio-item H3 {
  position: absolute;
  margin: 0 5%;
  padding: 0 0 4px;
  transition: 0.2s linear;
  text-align: center;
  left: 0px;
  color: #fff;
  width: 90%;
  bottom: -100px;
  font-size: 25px;
}

.portfolio-list .portfolio-item:hover H3 {
  transform: translateY(-100px);
}

.single-portfolio .row.mt {
  margin-top: 0;
}

.single-portfolio .pt {
  padding-bottom: 30px;
}

.single-portfolio .details-key,
.single-portfolio .details-value {
  line-height: 30px;
  vertical-align: middle;
  margin-bottom: 10px;
}

.single-portfolio IMG.collaborating-agency-logo {
  filter: grayscale(100%);
}

.single-portfolio H3 {
  text-align: center;
}

.single-portfolio HR.portfolio-separator {
  width: 50%;
  margin: 0px auto 50px auto;
}

/*
	Containers
*/

#content-wrapper,
#example-wrapper {
  height: 100%;
}

.scrollContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}

.scrollContainer {
  height: 100%;
  margin: auto;
}

#example-wrapper .scrollContent {
  height: 100%;
}

#example-wrapper .scrollContainer .scrollContent {
  width: 100%;
  height: auto;
}

#example-wrapper .scrollContent {
  font-size: 0;
}

#example-wrapper .scrollContent div {
  font-size: 13px;
}

.fullheight {
  min-height: 100%;
}

.doublewidth {
  min-width: 200%;
}

.portfolio-image .spacer {
  text-align: center;
  min-height: 100px;
}

.portfolio-image .spacer.s0 {
  min-height: 1px;
}

.portfolio-image .spacer.s1 {
  min-height: 100px;
}

.portfolio-image .spacer.s2 {
  min-height: 200px;
}

.portfolio-image .spacer.s3 {
  min-height: 300px;
}

.portfolio-image .spacer.s4 {
  min-height: 400px;
}

.portfolio-image .spacer.s5 {
  min-height: 500px;
}

.portfolio-image .spacer.s6 {
  min-height: 600px;
}

.portfolio-image .spacer.s7 {
  min-height: 700px;
}

.portfolio-image .spacer.s8 {
  min-height: 800px;
}

.portfolio-image .spacer.s9 {
  min-height: 900px;
}

.portfolio-image .spacer.s10 {
  min-height: 1000px;
}

.portfolio-image .spacer.s_viewport {
  min-height: 100%;
}

.portfolio-image .spacer.s_viewport {
  min-width: 100%;
}

.portfolio-image .hint {
  display: inline-block;
  position: relative;
  border: 0px solid white;
  text-align: center;
  vertical-align: middle;
  padding: 0 5px;
  min-width: 100px;
  width: 100%;
  margin: 0 auto 0 auto;
  background-color: #1abc9c;
  color: #FFF;
  opacity: 1;
  transition: opacity .25s ease-in-out;
  z-index: 99;
}

.portfolio-image .hint.active {
  opacity: 1;
}

.portfolio-image .hint H5 {
  color: #FFF;
  text-align: center;
  font-size: 16px;
}

.portfolio-image .hint P {
  margin: 5px 15px 5px 15px;
  font-size: 14px;
}

.portfolio-image .hint A {
  color: white;
}

.portfolio-image .hint.right {
  margin-left: -10px;
  border-radius: 0px 8px;
  box-shadow: 3px 3px 8px #CCC;
}

.portfolio-image .hint.right:after {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 0;
  height: 0;
  margin-left: -40px;
  margin-top: 0px;
  content: ' ';
  border-top: 0px solid transparent;
  border-right: 20px solid #1abc9c;
  border-bottom: 20px solid transparent;
  border-left: 20px solid transparent;
}

.portfolio-image .hint.right H5 {
  text-align: left;
  margin-left: 10px;
}

.portfolio-image .hint.right H5 I {
  margin-left: 8px;
}

.portfolio-image .hint.right P {
  text-align: left;
}

.portfolio-image .hint.left {
  margin-left: 10px;
  border-radius: 8px 0px;
  box-shadow: -3px 3px 8px #CCC;
}

.portfolio-image .hint.left:after {
  position: absolute;
  top: 0%;
  right: 0%;
  width: 0;
  height: 0;
  margin-right: -40px;
  margin-top: 0px;
  content: ' ';
  border-top: 0px solid transparent;
  border-left: 20px solid #1abc9c;
  border-bottom: 20px solid transparent;
  border-right: 20px solid transparent;
}

.portfolio-image .hint.left H5 {
  text-align: right;
  margin-right: 15px;
}

.portfolio-image .hint.left H5 I {
  margin-right: 8px;
}

.portfolio-image .hint.left P {
  text-align: right;
}

#white {
  padding-top: 30px;
}

#grey {
  padding-top: 30px;
}

HR.blog-header-separator {
  width: 80%;
  margin: 20px auto;
}

.page-template-blog .post-meta {
  margin-bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  width: 100%;
}

.page-template-blog .post-meta .meta-space {
  font-size: 0.95em;
  color: #b3b3b3;
}

.page-template-blog .post-meta .meta-space > SPAN {
  padding-right: 8px;
  margin-right: 4px;
}

.page-template-blog .post-meta .meta-space > SPAN:not(:last-child):after {
  position: relative;
  top: 3px;
  left: 8px;
  height: 15px;
  content: no-close-quote;
  width: 1px;
  background: #b3b3b3;
  display: inline-block;
  transform: rotate(25deg);
}

.page-template-blog .post-meta .meta-space I {
  margin-right: 4px;
}

.page-template-blog .post-meta .meta-space A {
  color: #b3b3b3;
  transition: 0.4s ease-in;
  transition-property: color;
}

.page-template-blog .post-meta .meta-space A:hover,
.page-template-blog .post-meta .meta-space A:active {
  color: #1abc9c;
}

.page-template-blog P.blog-excerpt {
  text-align: justify;
  padding: 0 20px;
}

.page-template-blog P.read-more {
  text-align: center;
}

.page-template-blog P.read-more A {
  color: #7B7B7B;
  transition: color 200ms ease-in-out;
}

.page-template-blog P.read-more A .fa.fa-arrow-right {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  margin-left: 8px;
}

.page-template-blog P.read-more A:hover {
  color: #1abc9c;
}

.page-template-blog P.read-more A:hover .fa.fa-arrow-right {
  opacity: 1;
}

.page-template-blog .post-entry {
  margin-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.page-template-blog .post-entry P:not(.read-more) {
  text-align: justify;
}

.page-template-blog .pager {
  margin: 40px 0 60px 0;
}

.single-post #white {
  padding-bottom: 5px;
}

.single-post .post-meta {
  margin-bottom: 0;
  display: inline-block;
  width: 100%;
}

.single-post .post-meta .meta-space {
  font-size: 0.95em;
  color: #b3b3b3;
}

.single-post .post-meta .meta-space > SPAN {
  padding-right: 8px;
  margin-right: 4px;
}

.single-post .post-meta .meta-space > SPAN:not(:last-child):after {
  position: relative;
  top: 3px;
  left: 8px;
  height: 15px;
  content: no-close-quote;
  width: 1px;
  background: #b3b3b3;
  display: inline-block;
  transform: rotate(25deg);
}

.single-post .post-meta .meta-space I {
  margin-right: 4px;
}

.single-post .post-meta .meta-space A {
  color: #b3b3b3;
  transition: 0.4s ease-in;
  transition-property: color;
}

.single-post .post-meta .meta-space A:hover,
.single-post .post-meta .meta-space A:active {
  color: #1abc9c;
}

.single-post H2 {
  margin-top: 40px;
  text-align: left;
}

.single-post P.blog-content {
  text-align: justify;
}

.single-post .wp-caption {
  border-radius: 4px;
}

.single-post .wp-caption P.wp-caption-text {
  text-align: center;
  font-size: 1.3rem;
  padding: 25px 10px 10px 10px;
  color: #7B7B7B;
}

.single-post .post-entry P {
  text-align: justify;
}

.single-post PRE[class*="language-"].line-numbers {
  margin-bottom: 30px;
}

.single-post .footnote_plugin_tooltip_text {
  color: #b3b3b3;
}

.single-post .author-avatar {
  display: none;
}

.single-post #author-meta {
  display: none;
}

.blog-list-home P {
  color: #333;
}

.blog-list-home P.blog-entry-date {
  font-size: 14px;
}

.pager .next > A,
.pager .next > SPAN {
  float: left;
}

.pager .previous > A,
.pager .previous > SPAN {
  float: right;
}

.pager LI A,
.pager LI SPAN {
  position: relative;
  vertical-align: top;
  padding: 5px 10px;
  font-size: 16px;
  color: #FFF !important;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  background: #f1c40f;
  border: 0;
  border-bottom: 2px solid #e2b607;
  cursor: pointer;
  box-shadow: inset 0 -2px #e2b607;
  opacity: 0.85;
  transition: opacity .25s ease-in-out;
  background: #859596;
  border-bottom: 2px solid #95a5a6;
  box-shadow: inset 0 -2px #95a5a6;
  border-radius: 4px;
}

.pager LI A:active,
.pager LI SPAN:active {
  background: #859596;
  border-bottom: 2px solid #95a5a6;
  box-shadow: inset 0 -2px #95a5a6;
  top: 1px;
  outline: none;
  box-shadow: none;
  color: #FFF;
  opacity: 1;
}

.pager LI A:hover,
.pager LI SPAN:hover {
  background: #859596;
  border-bottom: 2px solid #95a5a6;
  box-shadow: inset 0 -2px #95a5a6;
  opacity: 1;
  color: #FFF;
  text-decoration: none;
}

.page-template-template-contact .content {
  margin: 20px auto;
}

.page-template-template-contact hr {
  width: 65%;
  margin: 30px auto 30px auto;
}

.page-template-template-contact .nf-form-fields-required {
  display: none;
}

.page-template-template-contact h4 {
  padding: 10px 0;
}

.page-template-template-contact h4 I.fa-calendar {
  margin-right: 15px;
}

.availability-type {
  font-weight: bold;
  text-align: left;
}

.availability-value {
  font-weight: normal;
  text-align: center;
}

.availability-note P {
  font-weight: normal;
  text-align: left;
  font-size: 12px;
  padding: 15px 40px 15px 5px;
}

.debugArrayFieldset,
.debugTextFieldset {
  background-color: #F2F2F2;
  border: 1px solid #b3b3b3;
  border-radius: 4px;
  z-index: 999999;
  position: relative;
  margin: 5px auto;
  padding: 15px;
  color: #555;
}

.debugArrayFieldset PRE,
.debugTextFieldset PRE {
  text-align: left;
  text-transform: none;
}

.page-template-template-homepage.page-template-template-packages-php .packages-sale,
.page-template-template-packages.page-template-template-packages-php .packages-sale {
  margin: 35px 80px 65px;
}

.page-template-template-homepage .packages-sale H4,
.page-template-template-packages .packages-sale H4 {
  font-size: 24px;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.page-template-template-homepage .packages-sale I.fa.fa-snowflake-o,
.page-template-template-packages .packages-sale I.fa.fa-snowflake-o {
  margin-top: 15px;
  color: #FFF;
  font-size: 10em;
}

.page-template-template-homepage .packages-sale EM,
.page-template-template-packages .packages-sale EM {
  color: #777;
  font-size: 12px;
}

.page-template-template-homepage .packages-sale .coupon-code,
.page-template-template-packages .packages-sale .coupon-code {
  border: 1px dashed #555;
  padding: 2px;
}

.page-template-template-services .service-icon {
  font-size: 15rem;
  margin-top: -10px;
}

.page-template-template-services .service-icon:hover {
  color: #333;
}

.page-template-template-services .service-icon.wordpress:before {
  font-family: FontAwesome !important;
  content: "\f19a";
  font-size: 14rem;
}

.page-template-template-services .service-icon.woocommerce:before {
  font-family: WooCommerce !important;
  content: "\e03d";
}

.page-template-template-services .service-icon.back-end:before {
  font-family: FontAwesome !important;
  content: "\f233";
}

.page-template-template-services .service-icon.consulting:before {
  font-family: FontAwesome !important;
  content: "\f27e";
}

.page-template-template-services .service-icon.craft:before {
  content: "";
  display: block;
  height: 165px;
  background-image: url("/content/themes/stanleywp-child/assets/dist/images/craft-logo-gray.jpg");
  margin-top: 40px;
}

.page-template-template-services .col-lg-2:not(.col-lg-push-10) .service-icon {
  margin-top: -25px;
}

.page-template-template-services .ww,
.page-template-template-services .gw {
  padding-bottom: 30px;
  padding-top: 30px;
}

.page-template-template-services .ww {
  background-color: #fff;
}

.page-template-template-services .gw {
  background-color: #f2f2f2;
}

.page-template-template-service-group .white {
  background-color: #FFF;
  padding-bottom: 30px;
  padding-top: 30px;
}

.page-template-template-service-group .gray {
  background-color: #F2F2F2;
  padding-bottom: 30px;
  padding-top: 30px;
}

.page-template-template-service-group .service-icon {
  margin-top: 25px;
  font-size: 15rem;
  transition: color 0.25s ease-in-out;
}

.page-template-template-service-group .service-icon:hover {
  color: #1abc9c;
}

.page-template-default .white {
  background-color: #FFF;
  padding-bottom: 30px;
  padding-top: 30px;
}

.page-template-default .gray {
  background-color: #F2F2F2;
  padding-bottom: 30px;
  padding-top: 30px;
}

.page-template-default H2.mt {
  margin: 0;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 40px;
}

.page-template-default .service-types > DIV[class*='col-'] {
  min-height: 250px;
}

.page-template-default .service-types > DIV[class*='col-']:last-child {
  min-height: 110px;
}

.page-template-default .service-types .service-type-icon {
  margin-top: 15px;
  font-size: 4rem;
}

.page-template-default .service-types P {
  font-size: 14px;
}

.page-template-default .service-types .btn {
  margin-bottom: 60px;
}

.page-template-default.page-id-1030 P.contact {
  margin-top: 30px;
}

.service-list-home {
  overflow: hidden;
}

.service-list-home .service-item {
  width: 100%;
  height: 180px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 0 !important;
  margin-right: 20px;
  margin-bottom: 40px;
}

.service-list-home .service-item.lg-last {
  margin-right: 0px;
}

.service-list-home .service-item IMG {
  width: 100%;
  height: auto;
}

.service-list-home .service-item .gradient {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  background: linear-gradient(to bottom, transparent 25%, rgba(0, 0, 0, 0.1), #000 100%);
  cursor: pointer;
}

.service-list .portfolio-item {
  margin-bottom: 60px;
}

.single-service .row.mt {
  margin-top: 0;
}

.single-service .pt {
  padding-bottom: 30px;
}

.single-service .details-key,
.single-service .details-value {
  line-height: 30px;
  vertical-align: middle;
  margin-bottom: 10px;
}

.single-service IMG.collaborating-agency-logo {
  filter: grayscale(100%);
}

.single-service H3 {
  text-align: center;
}

.single-service HR.portfolio-separator {
  width: 50%;
  margin: 0px auto 50px auto;
}

.page-template-template-packages H1 {
  font-size: 3.2em;
}

.page-template-template-packages .packages {
  margin-top: 50px;
}

.page-template-template-packages .packages .panel H1,
.page-template-template-packages .packages .panel H2 {
  color: #333;
  font-size: 30px;
  font-weight: 300;
  margin: 15px;
}

.page-template-template-packages .packages .panel.panel-primary H2,
.page-template-template-packages .packages .panel.panel-danger H2 {
  color: #FFF;
}

.page-template-template-packages .packages .panel.panel-primary {
  margin-top: -50px;
}

.page-template-template-packages .packages .panel .panel-body {
  padding: 0px;
  text-align: center;
}

.page-template-template-packages .packages .panel .package-price {
  background-color: rgba(220, 220, 220, 0.17);
  box-shadow: 0 1px 0 #dcdcdc, inset 0 1px 0 #fff;
  padding: 20px;
  margin: 0;
}

.page-template-template-packages .packages .panel .package-price H1 {
  line-height: 1em;
  padding: 0;
  margin: 0;
}

.page-template-template-packages .packages .panel .subscript {
  font-size: 25px;
}

.page-template-template-packages .packages .panel .cnrflash {
  position: absolute;
  top: -9px;
  right: 4px;
  z-index: 1;
  /*Set overflow to hidden, to mask inner square*/
  overflow: hidden;
  /*Set size and add subtle rounding  		to soften edges*/
  width: 100px;
  height: 100px;
  border-radius: 3px 5px 3px 0;
}

.page-template-template-packages .packages .panel .cnrflash .cnrflash-inner {
  /*Set position, make larger then 			container and rotate 45 degrees*/
  position: absolute;
  bottom: 0;
  right: 0;
  width: 145px;
  height: 145px;
  -ms-transform: rotate(45deg);
  /* IE 9 */
  -o-transform: rotate(45deg);
  /* Opera */
  -moz-transform: rotate(45deg);
  /* Firefox */
  -webkit-transform: rotate(45deg);
  /* Safari and Chrome */
  -webkit-transform-origin: 100% 100%;
  /*Purely decorative effects to add texture and stuff*/
  /* Safari and Chrome */
  -ms-transform-origin: 100% 100%;
  /* IE 9 */
  -o-transform-origin: 100% 100%;
  /* Opera */
  -moz-transform-origin: 100% 100%;
  /* Firefox */
  background-image: linear-gradient(90deg, transparent 50%, rgba(255, 255, 255, 0.1) 50%), linear-gradient(0deg, transparent 0%, rgba(1, 1, 1, 0.2) 50%);
  background-size: 4px,auto, auto,auto;
  background-color: #aa0101;
  box-shadow: 0 3px 3px 0 rgba(1, 1, 1, 0.5), 0 1px 0 0 rgba(1, 1, 1, 0.5), inset 0 -1px 8px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 0 0 rgba(255, 255, 255, 0.2);
}

.page-template-template-packages .packages .panel .cnrflash .cnrflash-inner:before,
.page-template-template-packages .packages .panel .cnrflash .cnrflash-inner:after {
  content: " ";
  display: block;
  position: absolute;
  bottom: -16px;
  width: 0;
  height: 0;
  border: 8px solid #800000;
}

.page-template-template-packages .packages .panel .cnrflash .cnrflash-inner:before {
  left: 1px;
  border-bottom-color: transparent;
  border-right-color: transparent;
}

.page-template-template-packages .packages .panel .cnrflash .cnrflash-inner:after {
  right: 0;
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.page-template-template-packages .packages .panel .cnrflash .cnrflash-inner .cnrflash-label {
  /*Make the label look nice*/
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  padding-bottom: 5px;
  color: #fff;
  text-shadow: 0 1px 1px rgba(1, 1, 1, 0.8);
  font-size: 0.95em;
  font-weight: bold;
  text-align: center;
}

.page-template-template-packages .packages .panel .table {
  margin-bottom: 5px;
}

.page-template-template-packages .packages .panel .table TBODY TR.active {
  font-weight: bold;
}

.page-template-template-packages .white {
  background-color: #FFF;
  padding-bottom: 30px;
  padding-top: 30px;
}

.page-template-template-packages .gray {
  background-color: #F2F2F2;
  padding-bottom: 30px;
  padding-top: 30px;
}

.page-template-template-packages .benefit {
  margin-bottom: 50px;
}

.page-template-template-packages .benefit H4 {
  transition: 0.3s ease-in-out color;
  margin-top: 20px;
}

.page-template-template-packages .benefit H4:hover {
  color: #1abc9c;
}

.page-template-template-packages .benefit H4 I {
  font-size: 4em;
  margin-bottom: 25px;
}

.page-template-template-packages .benefit P {
  padding: 0px 30px;
}

.page-template-template-packages .benefit-text P {
  padding: 15px 25%;
}

.page-template-template-packages .benefit-divider {
  border-left: 1px solid #999;
  height: 80%;
  margin: auto;
}

.page-template-template-packages H3,
.page-template-template-packages H4 {
  margin-bottom: 15px;
  margin-top: 50px;
}

.page-template-template-packages .packages-details {
  margin-top: 50px;
}

.page-template-template-packages P {
  margin-bottom: 25px;
}

.page-template-template-packages P.no-mb {
  margin-bottom: 5px;
  margin-top: -10px;
}

.page-template-template-packages P.note {
  margin-bottom: 0px;
  margin-top: 0px;
}

.page-template-template-packages UL.package-benefits {
  text-align: left;
  list-style-type: disc;
  margin-bottom: 35px;
  margin-left: 25px;
}

.page-template-template-packages H2 {
  font-size: 3em;
  margin-bottom: 30px;
}

.page-template-template-packages [id='nf-form-2-cont'] {
  color: #424242;
}

.page-template-template-packages [id='nf-form-2-cont'] LABEL {
  font-size: 1.3em;
}

.page-template-template-packages [id='nf-form-2-cont'] .nf-field-description P {
  text-align: left;
  font-size: 14px;
}

pre.line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber;
}

pre.line-numbers > code {
  position: relative;
}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  /* works for line-numbers below 1000 lines */
  letter-spacing: -1px;
  border-right: 1px solid #999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.line-numbers .line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}

.line-numbers .line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #999;
  display: block;
  padding-right: 0.8em;
  text-align: right;
}

pre[class*="language-"] {
  -webkit-box-shadow: -1px 0px 0px 0px #1abc9c, 0px 0px 0px 1px #dfdfdf !important;
  -moz-box-shadow: -1px 0px 0px 0px #1abc9c, 0px 0px 0px 1px #dfdfdf !important;
  box-shadow: -1px 0px 0px 0px #1abc9c, 0px 0px 0px 1px #dfdfdf !important;
  border-left: 10px solid #1abc9c !important;
}

.comments-area .comment-meta .fn {
  font-size: 16px;
}

.comments-area .comment-meta .comment-date {
  font-size: 14px;
}

.comments-area .comment-meta A {
  color: #555;
}

.comments-area .bypostauthor .comment-body {
  background-color: rgba(26, 188, 156, 0.7);
  color: #FFF;
}

.comments-area .bypostauthor .comment-body CODE {
  color: #1abc9c;
}

.comments-area .bypostauthor .comment-body A {
  color: #FFF;
  font-weight: bold;
}

#mc_embed_signup FORM {
  padding: 0px !important;
}

#mc_embed_signup FORM LABEL[for='mce-EMAIL'] {
  color: #FFFFFF;
}

.page-template-template-calendar .post-entry {
  min-height: 500px;
}

.page-template-template-calendar .post-entry .ui.tbk-grid .tbk-row.tb-days > .tbk-column {
  min-height: 75px;
}

.page-template-template-calendar .post-entry .tbk-schedule-slot {
  width: 100%;
}

.widget-wrapper {
  padding-bottom: 0px;
}

.widget-wrapper:last-child {
  padding-bottom: 25px;
}

.widget-wrapper .textwidget P {
  margin-bottom: 0px;
}

.woocommerce div.product div.images img,
.woocommerce-page div.product div.images img {
  width: 92%;
}

.woocommerce div.product .summary,
.woocommerce-page div.product .summary {
  float: none;
  width: 98%;
}

.woocommerce div.product .summary .price,
.woocommerce-page div.product .summary .price {
  color: #666;
  font-size: 2.5em;
}

.woocommerce div.product .summary .woocommerce-product-details__short-description p,
.woocommerce-page div.product .summary .woocommerce-product-details__short-description p {
  padding: 15px;
}

.woocommerce div.product .woocommerce-tabs ul.tabs,
.woocommerce-page div.product .woocommerce-tabs ul.tabs {
  margin: 0 0 0;
  padding: 0;
  text-align: left;
}

.woocommerce div.product .woocommerce-tabs ul.tabs li.description_tab,
.woocommerce-page div.product .woocommerce-tabs ul.tabs li.description_tab {
  display: none;
}

.woocommerce div.product .woocommerce-tabs .panel,
.woocommerce-page div.product .woocommerce-tabs .panel {
  padding: 15px;
}

.woocommerce div.product .woocommerce-tabs .panel P,
.woocommerce-page div.product .woocommerce-tabs .panel P {
  text-align: left;
}

.woocommerce div.product .woocommerce-tabs .panel H2,
.woocommerce-page div.product .woocommerce-tabs .panel H2 {
  text-align: left;
}

.woocommerce div.product .woocommerce-tabs .woo-product-subscription-package-extra-text P,
.woocommerce-page div.product .woocommerce-tabs .woo-product-subscription-package-extra-text P {
  margin: 30px auto;
  text-align: center;
}

.woocommerce div.product form.cart .quantity,
.woocommerce-page div.product form.cart .quantity {
  float: none !important;
}

.woocommerce div.product form.cart .single_add_to_cart_button,
.woocommerce-page div.product form.cart .single_add_to_cart_button {
  float: none;
  margin-top: 5px;
}

.woocommerce div.product form.cart .price,
.woocommerce-page div.product form.cart .price {
  color: #1abc9c;
}

.woocommerce div.product form.cart .variations,
.woocommerce-page div.product form.cart .variations {
  float: left;
  width: 50%;
}

.woocommerce div.product form.cart a.reset_variations,
.woocommerce-page div.product form.cart a.reset_variations {
  display: none !important;
}

.woocommerce div.product form.cart .single_variation_wrap,
.woocommerce-page div.product form.cart .single_variation_wrap {
  float: left;
  width: 50%;
}

.woocommerce div.product form.cart .single_variation_wrap .woocommerce-variation.single_variation,
.woocommerce-page div.product form.cart .single_variation_wrap .woocommerce-variation.single_variation {
  float: left;
  width: 60%;
}

.woocommerce div.product form.cart .single_variation_wrap .woocommerce-variation.single_variation .woocommerce-variation-price,
.woocommerce-page div.product form.cart .single_variation_wrap .woocommerce-variation.single_variation .woocommerce-variation-price {
  float: left;
  width: 100%;
  text-align: left;
}

.woocommerce div.product form.cart .single_variation_wrap .woocommerce-variation-add-to-cart,
.woocommerce-page div.product form.cart .single_variation_wrap .woocommerce-variation-add-to-cart {
  float: left;
  width: 40%;
}

.woocommerce div.product div.summary,
.woocommerce-page div.product div.summary {
  float: none;
  width: 98%;
}

.woocommerce-checkout DL.variation {
  padding-left: 20px;
}

.woocommerce-checkout DL.variation DD {
  margin-bottom: 0 !important;
}

.woocommerce-checkout DL.variation DD:last-child {
  margin-bottom: 1em;
}

.woocommerce-checkout DL.variation DD P {
  font-size: 14px;
  font-weight: normal;
  line-height: 1.428571429;
}

.woocommerce-checkout .shop_table THEAD TH:not(.product-name) {
  text-align: center;
}

.woocommerce-checkout .shop_table THEAD TH.product-total {
  text-align: right;
  padding-right: 30px;
}

.woocommerce-checkout .shop_table TBODY .order_item .product-name {
  font-size: 16px;
}

.woocommerce-checkout .shop_table TBODY .order_item .product-name UL.wc-item-meta {
  font-size: 14px;
  margin: 5px 10px;
}

.woocommerce-checkout .shop_table TBODY .order_item .product-name UL.wc-item-meta LI {
  margin: 5px;
}

.woocommerce-checkout .shop_table TBODY .order_item .product-name UL.wc-item-meta LI STRONG.wc-item-meta-label {
  margin-right: 1em;
  font-size: 14px;
}

.woocommerce-checkout .shop_table TBODY .order_item .product-name UL.wc-item-meta LI P {
  font-size: 14px;
  line-height: 1.428571429;
}

.woocommerce-checkout .shop_table TBODY .order_item .product-quantity {
  text-align: center;
}

.woocommerce-checkout .shop_table TBODY .order_item .product-subtotal {
  text-align: right;
  padding-right: 30px;
}

.woocommerce-checkout .shop_table TFOOT {
  font-size: 16px;
}

.woocommerce-checkout .shop_table TFOOT TR TH:first-child {
  text-align: right;
  padding-right: 40px;
}

.woocommerce-checkout .shop_table TFOOT TR TD:last-child {
  text-align: right;
  padding-right: 30px;
}

.woocommerce-checkout input#submit.alt,
.woocommerce-checkout a.button.alt,
.woocommerce-checkout button.button.alt,
.woocommerce-checkout input.button.alt {
  background-color: #1abc9c;
}

.woocommerce-checkout input#submit.alt:hover,
.woocommerce-checkout a.button.alt:hover,
.woocommerce-checkout button.button.alt:hover,
.woocommerce-checkout input.button.alt:hover {
  background-color: #16a085;
}

.woocommerce-account .woocommerce-MyAccount-navigation {
  width: 20%;
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li {
  text-align: left;
}

.woocommerce-account .woocommerce-MyAccount-navigation ul li.is-active a {
  font-weight: bold;
  color: #7b7b7b;
  outline: 0;
  text-decoration: none;
}

.woocommerce-account .woocommerce-MyAccount-content {
  float: right;
  width: 75%;
}

.woocommerce-account .woocommerce-MyAccount-content .woocommerce-orders-table th:last-child {
  text-align: center;
}

.woocommerce-account .woocommerce-MyAccount-content .woocommerce-orders-table td:last-child {
  text-align: center;
}

.woocommerce-account .woocommerce-MyAccount-content .woocommerce-orders-table td.woocommerce-orders-table__cell-order-total {
  text-align: right;
}

.woocommerce-account .woocommerce-table__product-table.product-qty,
.woocommerce-account .woocommerce-table__product-qty.product-qty {
  text-align: right;
}

.woocommerce-account td.product-name {
  font-size: 16px;
}

.woocommerce-account td.product-name .wc-item-meta .wc-item-meta-label {
  margin-right: 15px;
}

.woocommerce-account td.product-name .wc-item-meta li {
  padding: 5px 10px;
}

.woocommerce-account td.product-name .wc-item-meta li p {
  font-size: 14px;
  line-height: 21px;
}

.woocommerce-cart .woocommerce-message {
  border-top-color: #16a085;
}

.woocommerce-cart .variation P {
  font-size: inherit;
  line-height: inherit;
  margin-bottom: inherit;
}

.woocommerce-cart .coupon INPUT[type='text'] {
  width: 115px !important;
}

.woocommerce-cart .checkout-button.alt {
  background-color: #859596 !important;
  opacity: 0.85 !important;
  transition: opacity .25s ease-in-out !important;
}

.woocommerce-cart .checkout-button.alt:hover {
  opacity: 1 !important;
}

.navbar-toggle {
  z-index: 999999;
}

.navbar-inverse {
  padding-top: 20px;
}

a.logo {
  transition: color 0.1s ease-in, background 0.1s ease-in;
}

.fa-logo-stack-size {
  font-size: 4em !important;
}

.fa-logo-outer-stack-color {
  color: #4D4D4D !important;
}

.fa-logo-inner-stack-color {
  color: #1ABC9C !important;
}

.logo-text {
  color: #FFF;
  font-size: 2.2em;
  font-family: 'Quicksand', sans-serif;
  line-height: 35px;
  margin: 18px 0 0 0px;
  text-transform: lowercase;
  word-wrap: normal;
}

.navbar-inverse .navbar-nav > li > a {
  font-weight: bold;
  border-top: 2px #1ABC9C solid;
}

.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .navbar-nav > li > a:hover {
  font-weight: bold;
  border-top: 2px #555 solid;
}

.home-content > p {
  margin-bottom: 25px;
}

.home-content h3 {
  margin: 2.5em 0 1em 0;
}

.home-content .wp-block-columns p {
  margin-bottom: 0;
}

#footer .copyright A {
  color: #7B7B7B;
  transition: color 0.1s ease-in-out;
}

#footer .copyright A:hover,
#footer .copyright A:active {
  color: #1abc9c;
}

@media (min-width: 40.063em) {
  .portfolio-list-home .portfolio-item H3 {
    display: block;
  }

  .portfolio-list-home .portfolio-item:hover H3 {
    transform: translateY(-100px);
  }

  .service-list-home .service-item H3 {
    display: block;
  }

  .service-list-home .service-item:hover H3 {
    transform: translateY(-120px);
  }
}

@media (min-width: 64.063em) {
  .portfolio-list-home .portfolio-item H3 {
    position: absolute;
    margin: 0 5%;
    padding: 0 0 4px;
    transition: 0.2s linear;
    text-align: center;
    left: 0px;
    color: #fff;
    width: 90%;
    bottom: -100px;
    font-size: 25px;
  }

  .service-list-home .service-item H3 {
    position: absolute;
    margin: 0 5%;
    padding: 0 0 4px;
    transition: 0.2s linear;
    text-align: center;
    left: 0px;
    color: #fff;
    width: 90%;
    bottom: -100px;
    font-size: 25px;
  }
}

@media (min-width: 1200px) {
  .woocommerce-account .container {
    width: 1440px;
  }
}

@media only screen and (max-width: 1200px) {
  .pet-projects-home .project {
    width: 50%;
  }
}

@media only screen and (max-width: 75em) {
  .single-portfolio .left-alley,
  .single-portfolio .right-alley {
    display: none;
  }

  .portfolio-list-home .portfolio-item H3 {
    display: none;
  }

  .portfolio-list-home .portfolio-item .gradient {
    display: none;
  }

  .service-portfolio .left-alley,
  .service-portfolio .right-alley {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .pet-projects-home .project {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .logo-text {
    font-size: 1.6em;
    line-height: 25px;
    margin: 0px 0 0 0px;
  }

  .fa-logo-stack-size {
    font-size: 2em !important;
  }
}

@media only screen and (max-width: 61.875em) {
  .portfolio-list-home .portfolio-item H3 {
    display: none;
  }

  .portfolio-list {
    /*.gradient {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: #FFF;
			background: linear-gradient(to right, rgba(255, 255, 255, 0.0) 30%, rgba(255, 255, 255, 0.9) 100%);
			cursor: pointer;
		}*/
  }

  .service-list-home .service-item H3 {
    display: none;
  }

  .service-list {
    /*.gradient {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: #FFF;
			background: linear-gradient(to right, rgba(255, 255, 255, 0.0) 30%, rgba(255, 255, 255, 0.9) 100%);
			cursor: pointer; 
		}*/
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .logo-text {
    font-size: 1.9em;
    line-height: 32px;
    margin: 10px 0px 0 0px;
    padding: 0 0 0 30px;
  }

  .fa-logo-stack-size {
    font-size: 3.25em !important;
  }
}